import { Layout, Row, Col, Button, Dropdown, Menu } from 'antd';
import { BiMenu } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import MenuLogo from '../menu/logo';
import { useIsAuthenticated, useLogout } from 'modules/auth';

const { Header } = Layout;

export default function MenuHeader() {
  const logout = useLogout();
  const isAuthenticated = useIsAuthenticated();
  const menu = (
    <Menu>
      {isAuthenticated ? (
        <>
          <Menu.Item>
            <Link to="/transactions">Transactions</Link>
          </Menu.Item>
          <Menu.Item onClick={logout}>Logout</Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item>
            <Link to="/pages/authentication/login">Login</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/pages/authentication/register">Register</Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <Header>
      <Row
        className="da-w-100 da-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col>
          <MenuLogo />
        </Col>
        <Col
          xl={16}
          lg={14}
          className="da-header-left-text da-d-flex-center"
        ></Col>

        <Col className="da-mobile-nav">
          <Dropdown overlay={menu} trigger={['click']}>
            <Row align="middle" justify="center" className="da-dropdown-box">
              <Col>
                <BiMenu size={35} />
              </Col>
            </Row>
          </Dropdown>
        </Col>

        <Col className="da-non-mobile-nav">
          {isAuthenticated ? (
            <Row align="middle">
              <Col className="da-mx-24">
                <Link to="/transactions" className="da-text-color-black-10">
                  Transactions
                </Link>
              </Col>
              <Col>
                <Button block type="default" onClick={logout}>
                  Logout
                </Button>
              </Col>
            </Row>
          ) : (
            <Row align="middle">
              <Col className="da-mx-24">
                <Link
                  to="/pages/authentication/login"
                  className="da-text-color-black-10"
                >
                  Login
                </Link>
              </Col>
              <Col>
                <Link to="/pages/authentication/register">
                  <Button block type="default">
                    Register
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Header>
  );
}
