import { lazy } from 'react';

const PagesRoutes = [
  // CHECKOUT
  {
    path: '/checkout/buy',
    component: lazy(() => import('../../view/checkout/buy')),
    layout: 'VerticalLayout',
  },
  {
    path: '/checkout/sell',
    component: lazy(() => import('../../view/checkout/sell')),
    layout: 'VerticalLayout',
  },
  {
    path: '/checkout/pending',
    component: lazy(() => import('../../view/checkout/pending')),
    layout: 'VerticalLayout',
  },
  {
    path: '/checkout/complete',
    component: lazy(() => import('../../view/checkout/complete')),
    layout: 'VerticalLayout',
  },

  // TRANSACTIONS
  {
    path: '/transactions',
    component: lazy(() => import('../../view/transactions')),
    layout: 'VerticalLayout',
  },

  // PAGE
  {
    path: '/pages/authentication/login',
    component: lazy(() => import('../../view/pages/authentication/login')),
    layout: 'VerticalLayout',
  },
  {
    path: '/pages/authentication/recover-password',
    component: lazy(
      () => import('../../view/pages/authentication/recover-password')
    ),
    layout: 'FullLayout',
  },
  {
    path: '/pages/authentication/register',
    component: lazy(() => import('../../view/pages/authentication/register')),
    layout: 'VerticalLayout',
  },
  {
    path: '/pages/authentication/reset-password',
    component: lazy(
      () => import('../../view/pages/authentication/reset-password')
    ),
    layout: 'FullLayout',
  },
  {
    path: '/pages/blank-page',
    component: lazy(() => import('../../view/pages/blank')),
    layout: 'VerticalLayout',
  },
  {
    path: '/pages/error-403',
    component: lazy(() => import('../../view/pages/errors/403')),
    layout: 'FullLayout',
  },
  {
    path: '/pages/error-404',
    component: lazy(() => import('../../view/pages/errors/404')),
    layout: 'FullLayout',
  },
  {
    path: '/pages/error-500',
    component: lazy(() => import('../../view/pages/errors/500')),
    layout: 'FullLayout',
  },
  {
    path: '/pages/error-502',
    component: lazy(() => import('../../view/pages/errors/502')),
    layout: 'FullLayout',
  },
  {
    path: '/pages/error-503',
    component: lazy(() => import('../../view/pages/errors/503')),
    layout: 'FullLayout',
  },
  {
    path: '/pages/coming-soon',
    component: lazy(() => import('../../view/pages/errors/coming-soon')),
    layout: 'FullLayout',
  },
  {
    path: '/pages/maintenance',
    component: lazy(() => import('../../view/pages/errors/maintenance')),
    layout: 'FullLayout',
  },
  {
    path: '/pages/password-is-changed',
    component: lazy(
      () => import('../../view/pages/lock-screen/password-is-changed')
    ),
    layout: 'FullLayout',
  },
  {
    path: '/pages/deactivated',
    component: lazy(() => import('../../view/pages/lock-screen/deactivated')),
    layout: 'FullLayout',
  },
  {
    path: '/pages/lock',
    component: lazy(() => import('../../view/pages/lock-screen/lock')),
    layout: 'FullLayout',
  },
];

export default PagesRoutes;
