import { Row, Col } from 'antd';
import { useHistory } from 'react-router';
import { useMakeTransactionMutation } from 'api';
import createNotification from 'lib/createNotification';
import { useIsAuthenticated } from 'modules/auth';
import { TokenTradeCard } from 'view/main/widgets';

export const Landing = () => {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const [makeTransaction, { loading: makeTransactionLoading }] =
    useMakeTransactionMutation({
      onError: () => {
        createNotification('error', 'Failed to exchange');
      },
      onCompleted: (result) => {
        const newTransaction = result.makeTransaction;
        history.replace(`/checkout/complete?id=${newTransaction?.id}`);
      },
    });

  const onMakeTransaction = (inputs: any) => {
    if (!isAuthenticated) {
			createNotification('warning', (
				<div>
					Please <a href="/pages/authentication/login">Login</a> to start exchanging tokens
				</div>
			));
      return;
    }
    try {
      makeTransaction({
        variables: {
          data: {
            ...inputs,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="da-landing-page">
      <Col
        span={24}
        className="da-py-sm-0 da-py-md-64 da-calculator-card-container"
      >
        <Row justify="center">
          <Col span={24} md={12} lg={8} xl={6}>
            <TokenTradeCard
              buyCallback={onMakeTransaction}
              sellCallback={onMakeTransaction}
              loading={makeTransactionLoading}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
