import { useMemo, useState, useEffect } from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import {
  Col,
  Divider,
  Select,
  Row,
  Form,
  Button,
  Skeleton,
  Input,
  Checkbox,
} from 'antd';
import TokenAmountInput from '../TokenAmountInput';
import {
  Currency,
  PaymentMethod,
  TransactionType,
  useEstimateTransactionLazyQuery,
  useFetchRemoteConfigQuery,
} from 'api';
import { useIsAuthenticated } from 'modules/auth';

export type TokenTradeFormProps = {
  submitLabel?: string;
  transactionType: TransactionType;
  exchangedCallback?: (trasactionInput: {
    type: TransactionType;
    method: PaymentMethod;
    currency: string;
    cryptoCurrency: string;
    cryptoRate: number;
    crypto: number;
    fiat: number;
    walletAddress: string;
    walletNetwork: string;
    fiatDepositAccount: string;
    fiatDepositAccountName: string;
    fiatDepositBankName: string;
  }) => void;
};

const normalizedRates = (a: number, b: number) => {
  return [a / a, b / a];
};

export const TokenTradeForm = ({
  transactionType,
  exchangedCallback,
  submitLabel = 'Exchange',
}: TokenTradeFormProps) => {
  const [form] = Form.useForm();
  const isAuthenticated = useIsAuthenticated();
  const [cryptoToFiatRate, setCryptoToFiatRate] = useState(0);
  const [rateStatement, setRateStatement] = useState('');
  const [fiatStatement, setFiatStatement] = useState('');
  const { data, loading } = useFetchRemoteConfigQuery();

  const { supportedPaymentMethods, supportedTokens } =
    data?.fetchRemoteConfig || {};

  const [estimateTransaction, { loading: estimateTransactionLoading }] =
    useEstimateTransactionLazyQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        console.log({ error });
      },
      onCompleted: (result) => {
        const cryptoCurrencyItem = supportedTokens?.find(
          (item) =>
            item.cryptoCurrency === result.estimateTransaction?.cryptoCurrency
        );
        const currencyItem = supportedPaymentMethods?.find(
          (item) => item.currency === result.estimateTransaction?.currency
        );

        const normalizedFiatAmount = normalizedRates(
          result?.estimateTransaction?.crypto as number,
          result?.estimateTransaction?.fiat as number
        )[1];

        setCryptoToFiatRate(normalizedFiatAmount);
        setRateStatement(`1 ${cryptoCurrencyItem?.name} is estimated `);
        setFiatStatement(
          `${currencyItem?.currency} ${normalizedFiatAmount.toFixed(3)}`
        );

        const fields = form.getFieldsValue();
        form.setFieldsValue({
          receivedConfig: {
            ...fields?.receivedConfig,
            amount:
              transactionType === TransactionType.Buy
                ? result?.estimateTransaction?.crypto
                : result?.estimateTransaction?.fiat?.toFixed(2),
          },
          paymentConfig: {
            ...fields?.paymentConfig,
            amount:
              transactionType === TransactionType.Buy
                ? result?.estimateTransaction?.fiat?.toFixed(2)
                : result?.estimateTransaction?.crypto,
          },
          walletNetwork: '',
        });
      },
    });

  const initialValues = {
    crytoCurrency:
      supportedTokens?.length && supportedTokens[0]?.cryptoCurrency,
    currency:
      supportedPaymentMethods?.length && supportedPaymentMethods[0]?.currency,
    paymentMethod:
      supportedPaymentMethods?.length && supportedPaymentMethods[0]?.method,
    fiat: 1000,
  };

  const onFinish = async (values: any) => {
    if (
      values?.paymentConfig?.amount &&
      values?.paymentConfig?.option?.value &&
      values?.receivedConfig?.amount &&
      values?.receivedConfig?.option?.value &&
      exchangedCallback
    ) {
      let currency: string,
        cryptoCurrency: string,
        crypto: string,
        fiat: string;
      switch (transactionType) {
        case TransactionType.Buy:
          currency = values?.paymentConfig?.option?.value;
          cryptoCurrency = values?.receivedConfig?.option?.value;
          crypto = values?.receivedConfig?.amount;
          fiat = values?.paymentConfig?.amount;
          break;
        case TransactionType.Sell:
          currency = values?.receivedConfig?.option?.value;
          cryptoCurrency = values?.paymentConfig?.option?.value;
          crypto = values?.paymentConfig?.amount;
          fiat = values?.receivedConfig?.amount;
          break;
      }

      exchangedCallback({
        type: transactionType,
        method: values?.paymentMethod || initialValues.paymentMethod,
        currency,
        cryptoCurrency,
        cryptoRate: cryptoToFiatRate,
        crypto: parseFloat(crypto),
        fiat: parseFloat(fiat),
        walletAddress: values?.walletAddress,
        walletNetwork: values?.walletNetwork,
        fiatDepositAccount: '',
        fiatDepositAccountName: '',
        fiatDepositBankName: '',
      });
    }
  };

  const onCryptoInputChange = (newAmount: number) => {
    if (cryptoToFiatRate > 0) {
      const fields = form.getFieldsValue();
      const newFiat = newAmount * cryptoToFiatRate;
      form.setFieldsValue({
        ...(transactionType === TransactionType.Buy && {
          paymentConfig: {
            ...fields.paymentConfig,
            amount: newFiat.toFixed(2),
          },
        }),
        ...(transactionType === TransactionType.Sell && {
          receivedConfig: {
            ...fields.receivedConfig,
            amount: newFiat.toFixed(2),
          },
        }),
      });
    }
  };

  const onCurrencyInputChange = (newAmount: number) => {
    if (cryptoToFiatRate > 0) {
      const fields = form.getFieldsValue();
      const newCrypto = newAmount / cryptoToFiatRate;
      form.setFieldsValue({
        ...(transactionType === TransactionType.Buy && {
          receivedConfig: {
            ...fields.receivedConfig,
            amount: newCrypto.toFixed(5),
          },
        }),
        ...(transactionType === TransactionType.Sell && {
          paymentConfig: {
            ...fields.paymentConfig,
            amount: newCrypto.toFixed(5),
          },
        }),
      });
    }
  };

  const onDropdownChange = (option: any) => {
    // refresh the config with new currency and cryptocurrent
    estimateTransaction({
      variables: {
        fiat: initialValues?.fiat,
        type: transactionType,
        currency: Currency.Myr,
        cryptoCurrency: option?.value as any,
      },
    });
  };

  const currencyOptions = useMemo(
    () =>
      Array.isArray(supportedPaymentMethods)
        ? supportedPaymentMethods.map((item) => ({
            id: item.currency,
            label: item.currency,
            value: item.currency,
          }))
        : [],
    [supportedPaymentMethods]
  );

  const tokenOptions = useMemo(
    () =>
      Array.isArray(supportedTokens)
        ? supportedTokens.map((item) => ({
            id: item.cryptoCurrency,
            label: item.name,
            value: item.cryptoCurrency,
            icon: item.logo,
            networks: item.networks,
          }))
        : [],
    [supportedTokens]
  );

  const newReceivedConfig = form.getFieldValue('receivedConfig');

  const supportedNetworks = useMemo(() => {
    if (newReceivedConfig?.option?.id) {
      return newReceivedConfig?.option?.networks;
    }
    return [];
  }, [newReceivedConfig]);

  // set rates initially
  useEffect(() => {
    if (supportedPaymentMethods?.length && supportedTokens?.length) {
      estimateTransaction({
        variables: {
          fiat: initialValues?.fiat,
          type: transactionType,
          currency: initialValues?.currency as any,
          cryptoCurrency: initialValues?.crytoCurrency as any,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(supportedPaymentMethods),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(supportedTokens),
  ]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Col span={24}>
        {/* <Row>
          <Col span={24} className="da-select da-country-select">
            <div className="da-font-weight-600 da-p1-body da-pb-12">
              Payment Method
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="paymentMethod" rules={[{ required: true }]}>
              <Radio.Group size="large">
                {supportedPaymentMethods?.map((item) => (
                  <Radio.Button key={item.method} value={item.method}>
                    {item.method}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row> */}
        <Row className="da-pb-24">
          <Col>
            <div className="da-font-weight-400">{rateStatement}</div>
            <div className="h5">{fiatStatement}</div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="paymentConfig"
              rules={[
                () => ({
                  validator(_, value) {
                    if (cryptoToFiatRate > 0 && parseFloat(value.amount) <= 0) {
                      return Promise.reject(
                        new Error('Amount must be greater than 0')
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TokenAmountInput
                label={'You Pay'}
                dropdownOptions={
                  transactionType === TransactionType.Buy
                    ? currencyOptions
                    : tokenOptions
                }
                onInputChange={
                  transactionType === TransactionType.Buy
                    ? onCurrencyInputChange
                    : onCryptoInputChange
                }
                onDropdownChange={onDropdownChange}
                disabledDropdown={
                  transactionType === TransactionType.Buy ? true : false
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="da-exchange-info-container">
            <div className="da-exchange-container">
              <div className="da-middle-line" />
              <div className="da-circle" style={{ pointerEvents: 'none' }}>
                <FaExchangeAlt size={15} />
              </div>
            </div>
            <div className="da-exchange-info">
              {/* <i>Max limit 2000</i> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="receivedConfig"
              rules={[
                () => ({
                  validator(_, value) {
                    if (cryptoToFiatRate > 0 && parseFloat(value.amount) <= 0) {
                      return Promise.reject(
                        new Error('Amount must be greater than 0')
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TokenAmountInput
                label={"You'll receive estimated"}
                dropdownOptions={
                  transactionType === TransactionType.Buy
                    ? tokenOptions
                    : currencyOptions
                }
                onInputChange={
                  transactionType === TransactionType.Buy
                    ? onCryptoInputChange
                    : onCurrencyInputChange
                }
                onDropdownChange={onDropdownChange}
                disabledDropdown={
                  transactionType === TransactionType.Buy ? false : true
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {isAuthenticated && transactionType === TransactionType.Buy && (
          <>
            <Divider />
            <Row>
              <Col className="da-w-100">
                <Form.Item
                  name="walletNetwork"
                  label="Wallet Network"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please fill up Wallet Network',
                    },
                  ]}
                >
                  <Select>
                    {supportedNetworks.map((item: any) => (
                      <Select.Option value={item.name} key={item.name}>
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="da-w-100">
                <Form.Item
                  name="walletAddress"
                  label="Wallet Address"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please fill up Wallet Address',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  name="verified"
                  valuePropName="checked"
                  className="da-pt-18"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Please verify your wallet address')
                        );
                      },
                    }),
                  ]}
                >
                  <Checkbox>
                    I verify that this wallet address belongs to me. And
                    understand that sending it to someone else's wallet may
                    result in a loss of funds.
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {isAuthenticated && (
          <Row>
            <Col>
              <Form.Item
                name="agreeDisclaimer"
                valuePropName="checked"
                className="da-pt-12 da-pb-18"
                required
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Please agree to the disclaimer')
                      );
                    },
                  }),
                ]}
              >
                <Checkbox>
                  I have read and agree to the disclaimer. Click&nbsp;
                  <a
                    href="https://tokenbinder.com/disclaimer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  &nbsp;to read the full disclaimer.
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row className="da-pt-12">
          <Col span={24}>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={estimateTransactionLoading}
            >
              <FaExchangeAlt size={20} color="white" className="da-mr-8" />{' '}
              {submitLabel}
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
