import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { BiChevronDown } from 'react-icons/bi';

type DropdownOption = {
  id: string;
  label: string;
  value: string;
  icon?: string;
};

type TokenAmountInputProps = {
  value?: any;
  label: string;
  inputValue?: number;
  onChange?: (value: { amount: number; option: DropdownOption }) => void;
  onInputChange: (value: number) => void;
  onDropdownChange: (option: DropdownOption) => void;
  dropdownValue?: string;
  disabled?: boolean;
  disabledDropdown?: boolean;
  loading?: boolean;
  dropdownOptions: DropdownOption[];
};

const TokenAmountInput = ({
  value,
  label,
  dropdownOptions,
  onChange: onIncomingChange,
  onInputChange,
  onDropdownChange,
  disabled,
  disabledDropdown,
  loading,
}: TokenAmountInputProps) => {
  const dropdownListRef = useRef<any>(null);
  const [amount, setAmount] = useState(0);
  const [dropdownListVisible, setDropdownListVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]);
  useOnClickOutside(dropdownListRef, () => setDropdownListVisible(false));

  const onChange = (e: any) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    onInputChange(parseFloat(newAmount || ''));
  };

  const handleOnOptionClick = (item: any) => {
    setSelectedOption(item);
    setDropdownListVisible(false);
    onDropdownChange(item);
  };

  useEffect(() => {
    setDropdownListVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    onIncomingChange && onIncomingChange({ amount, option: selectedOption });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedOption), amount]);

  useEffect(() => {
    setSelectedOption(dropdownOptions[0]);
    setAmount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dropdownOptions)]);

  useEffect(() => {
    if (value && value.option) {
      setAmount(value.amount);
      setSelectedOption(value.option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  return (
    <div className="da-token-input">
      <div>
        <div className="da-amount-input-container">
          <div className="da-input-label">{label}</div>
          <input
            className="da-amount-input"
            onChange={onChange}
            type="number"
            step="0.01"
            disabled={disabled}
            value={amount}
          />
          {loading && <Spin className="input-spinner" />}
        </div>
        <div
          className="da-dropdown-btn"
          onClick={() =>
            !disabledDropdown &&
            setDropdownListVisible((prevState) => !prevState)
          }
        >
          {selectedOption?.icon && (
            <img src={selectedOption.icon} alt="token-selected" />
          )}
          <span>{selectedOption?.value}</span>
          {!disabledDropdown && <BiChevronDown />}
        </div>
      </div>
      <div
        className={`da-dropdown-list ${
          !dropdownListVisible ? 'da-d-hidden' : ''
        }`}
        ref={dropdownListRef}
      >
        {dropdownOptions.map((item) => (
          <div
            key={item.id}
            className="da-dropdown-option"
            onClick={() => handleOnOptionClick(item)}
          >
            <div className="da-option-value">
              {item?.icon && <img src={item.icon} alt="token-selected" />}
              <span>{item.value}</span>
            </div>
            <div className="da-option-label">{item.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TokenAmountInput;
