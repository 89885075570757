import { notification } from 'antd';

import {
  RiCloseFill,
  RiCheckboxCircleFill,
  RiErrorWarningFill,
} from 'react-icons/ri';

const ALERT_CLASS_MAP = {
  success: {
    icon: <RiCheckboxCircleFill style={{ color: '#00F7BF' }} />,
  },
  info: {
    icon: <RiErrorWarningFill style={{ color: '#1BE7FF' }} />,
  },
  warning: {
    icon: <RiErrorWarningFill style={{ color: '#FFC700' }} />,
  },
  error: {
    icon: <RiErrorWarningFill style={{ color: '#FF0022' }} />,
  },
};

const createNotification = (
  type: keyof typeof ALERT_CLASS_MAP,
  message: any,
  description?: any
) => {
  (notification as any)[type]({
    message,
    description,
    icon: ALERT_CLASS_MAP[type].icon,
    closeIcon: (
      <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
    ),
    className: ALERT_CLASS_MAP[type] || '',
    duration: type === 'error' ? 0 : undefined,
  });
};

export default createNotification;
