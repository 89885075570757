import { Card, Spin, Tabs } from 'antd';
import { TransactionType } from 'api';
import { TokenTradeForm, TokenTradeFormProps } from '../TokenTradeForm';

const { TabPane } = Tabs;

export type TokenTradeCardProps = {
  buySubmitLabel?: string;
  sellSubmitLabel?: string;
  buyCallback?: TokenTradeFormProps['exchangedCallback'];
  sellCallback?: TokenTradeFormProps['exchangedCallback'];
  loading?: boolean;
};

const TokenTradeCard = ({
  buySubmitLabel,
  sellSubmitLabel,
  buyCallback,
  sellCallback,
  loading,
}: TokenTradeCardProps) => {
  return (
    <Card className="da-token-trade-card">
      {loading && (
        <div className="da-loading-container da-position-absolute da-inset-0 da-h-100 w-h-100">
          <Spin />
        </div>
      )}
      <Tabs defaultActiveKey="1" size="large">
        <TabPane key="1" tab="Buy">
          <TokenTradeForm
            exchangedCallback={buyCallback}
            transactionType={TransactionType.Buy}
            submitLabel={buySubmitLabel}
          />
        </TabPane>
        <TabPane key="2" tab="Sell">
          <TokenTradeForm
            exchangedCallback={sellCallback}
            transactionType={TransactionType.Sell}
            submitLabel={sellSubmitLabel}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default TokenTradeCard;
